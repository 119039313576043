<template>
	<div class="nav-list">
		<ul class="list">
			<li :class="$route.name=='数据统计'?'current':''" @click="$router.push('/')">首页</li>
			<li @click="bindnav(item,index)" :class="$route.name==item.name?'current':''" v-for="(item,index) in  list"
				:key="index">{{item.name}}<i @click.stop="binclose(index)" class="icon iconfont icon-guanbi"></i></li>
		</ul>
	</div>
</template>·

<script>
	import BScroll from 'better-scroll'
	let scroll = new BScroll('.wrapper')
	export default {
		data() {
			return {
				list: [],
				btnList: [],
			}
		},
		computed: {
			list() {
				return this.$store.state.navlist
			}
		},
		methods: {
			// 点击路由
			bindnav(item, index) {
				this.btnList = []
				this.$router.push(item.path)
				if (item.children) {
					item.children.forEach(i => {
						this.btnList.push(i.typename)
					})
					this.$store.dispatch('BTNLIST', this.btnList)
				}
				/*滑动到指定索引的导航节点，并将其显示在可视区*/
				scroll.scrollToElement(document.querySelector('.list li:nth-child(' + index + ')'), null, true, true)
			},
			// 关闭路由
			binclose(index) {
				// 路由数组
				let list = this.$store.state.navlist
				// 路由长度
				let leg = this.$store.state.navlist.length
				// 获取当前的 前一个元素
				let findex = index - 1
				// 获取当前的 后一个元素
				let nindex = index + 1

				// 路由跳转
				if (index == 0 && leg == 1) {
					list.splice(index, 1)
					this.$router.push('/')
				} else if (index < leg && index != leg - 1) {
					var path = ''
					if (index == 0) {
						path = list[index + 1].path
					} else {
						path = list[index - 1].path
					}
					if (this.$route.name == list[index].name) {
						this.$router.push(path)
					}
					list.splice(index, 1)
				} else if (index == leg - 1) {
					if (this.$route.name == list[index].name) {
						this.$router.push(list[index - 1].path)
					}
					list.splice(index, 1)
				}
				// 提交路由
				this.$store.dispatch('GETNAVLIST', list)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.nav-list {
		width: 100%;
		overflow: hidden;
	}

	.list {
		padding: 10px 10px 0 10px;
		width: 100%;
		overflow-y: auto;

		.current {
			color: #5473E8;
		}

		.current i {
			width: 15px;
			display: inline-block;
		}

		li:hover i {
			width: 15px;
			display: inline-block;
		}

		li {
			display: inline-flex;
			align-items: center;
			border-radius: 3px;
			height: 30px;
			line-height: 30px;
			padding: 0 10px;
			background-color: #fff;
			font-size: 12px;
			margin-right: 10px;
			color: #909399;
			cursor: pointer;
			margin-bottom: 10px;

			i:hover {
				background-color: #5473E8;
				color: #FFFFFF;
			}

			i {
				transition: all .3s;
				height: 18px;
				text-align: center;
				line-height: 18px;
				font-size: 14px;
				margin-left: 5px;
				width: 0;
				overflow: hidden;
			}
		}
	}
</style>
