import request from './request'

const api = {
	// 列表
	lists: '/SystemAdmin/index',
	// 新增
	add: '/SystemAdmin/create',
	// 删除
	dele: '/SystemAdmin/delete',
	// 详情
	read: '/SystemAdmin/read',
	// 编辑
	edit: '/SystemAdmin/update',
	// 角色列表
	SystemRole: '/SystemRole/list',
	// 统计数据
	statistics: '/System/statistics',
	// 重置密码
	resetpwd: '/SystemAdmin/reset',
	// 获取配置信息
	configinfo: '/System/getConfig',
	// 地区树状列表
	getList: '/District/index',
	// 用户列表
	Userlist: '/User/getParentList',
	// 部门
	bmlists: '/District/index',
	// APP账号添加
	create: '/User/create',
	// 地区列表
	countylists: '/District/index',
	// 职务列表
	PostsList: '/Posts/getList',
	// 用户详情
	userread: '/User/read',
}

export {
	request,
	api
}
