<template>
	<div class="page-right" :class="[IsCollap?'current':'']">
		<!-- 头部 -->
		<Head></Head>
		<!-- 路由菜单 -->
		<Nav></Nav>
		<!-- 内容区 -->
		<div class="container">
			<div class="container_view">
				<router-view v-slot="{ Component }">
					<keep-alive>
						<component :is="Component" :key="$route.name" v-if="$route.meta.keepAlive" />
					</keep-alive>
					<component :is="Component" :key="$route.name" v-if="!$route.meta.keepAlive" />
				</router-view>
			</div>
		</div>
	</div>
</template>

<script>
	import Head from './head.vue'
	import Nav from './nav.vue'
	export default {
		components: {
			Head,
			Nav
		},
		data() {
			return {
				IsCollap: false
			}
		},
		mounted() {
			var that = this
			this.$EventBus.on('IsCollapse', function() {
				that.IsCollap = !that.IsCollap
				console.log(that.IsCollap)
			})
		}
	}
</script>


<style lang="scss" scoped>
	.current {
		width: calc(100% - 60px) !important;
	}

	.page-right {
		width: calc(100% - 255px);
		display: flex;
		box-sizing: border-box;
		flex-direction: column;
		height: 100vh;

		.container {
			width: 100%;
			flex: 1;
			overflow: hidden;
			margin-bottom: 10px;

			.container_view {
				height: 100%;
				width: 100%;
				padding: 0 10px;
				border-radius: 3px;
			}
		}
	}
</style>
