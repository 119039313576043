<template>
	<div class="head">
		<i class="icon iconfont icon-daohang" @click="isCollapse"></i>
		<view class="route-nav">
			<span @click="$router.push('/')" class="home">首页</span>
			<span class="line">/</span>
			<span>{{crumbname}}</span>
			<span v-if="sjname">{{sjname}}</span>
			<span class="line" v-if="sjname">/</span>
			<span v-if="qyname">{{qyname}}</span>
		</view>
		<!-- 用户 -->
		<div class="user">
			<div class="msg" v-if="$store.state.userinfo.roles==2">
				<el-badge type="primary" @click="examine" :hidden="setnum.user_check>0?false:true"
					:value="setnum.user_check" class="item">
					<p>待审核用户</p>
				</el-badge>
			</div>
			<div class="msg" v-if="$store.state.userinfo.roles==2">
				<el-badge @click="delay" :hidden="setnum.delay_order>0?false:true" :value="setnum.delay_order"
					class="item">
					<p>申请延期工单</p>
				</el-badge>
			</div>
			<div class="msg" v-if="$store.state.userinfo.roles==2">
				<el-badge type="warning" @click="chargeback" :hidden="setnum.back_order>0?false:true"
					:value="setnum.back_order" class="item">
					<p>申请退单工单</p>
				</el-badge>
			</div>
			<div class="username">
				<el-dropdown trigger="click">
					{{setdata.account}}
					<span>
						<img v-if="setdata.avatar" :src="setdata.avatar" />
						<img v-else src="../../assets/img/tx.png" />
					</span>
					<i class="icon iconfont icon-xiala"></i>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item @click="$router.push('/info')">个人资料</el-dropdown-item>
							<el-dropdown-item @click="signout">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/user";
	export default {
		data() {
			return {
				crumbname: '数据统计',
				setdata: {},
				qyname: '',
				sjname: '',
				timer: null,
				setnum: {},
				btnList: []
			}
		},
		computed: {
			crumbname() {
				return this.$route.name
			},
			qyname() {
				return this.$route.query.name || this.$route.query.ername
			},
			sjname() {
				return this.$route.query.typename
			}
		},
		mounted() {
			this.getinfo()
			this.settime()
			this.getnum()
			var that = this
			this.$EventBus.on('RefreshUser', function() {
				that.getinfo()
			})
		},
		methods: {
			// 申请退单
			chargeback() {
				this.$router.push({
					path: '/retreat'
				})
				this.setbtn("工单管理", 1)
			},
			// 申请延期
			delay() {
				this.$router.push({
					path: '/delay'
				})
				this.setbtn("工单管理", 3)
			},
			// 审核用户
			examine() {
				this.$router.push({
					path: '/trial'
				})
				this.setbtn("审核管理", 0)
			},
			// 处理按钮
			setbtn(name, num) {
				this.btnList = []
				this.$store.state.menulist.forEach(item => {
					console.log(item)
					if (item.name == name) {
						item.children[num].children.forEach(i => {
							this.btnList.push(i.typename)
						})
					}
				})
				this.$store.dispatch('BTNLIST', this.btnList)
			},
			// 轮询
			settime() {
				if(this.$store.state.userinfo.roles==2) {
					let that = this
					this.timer = window.setInterval(() => {
						if (sessionStorage.getItem('token')) {
							that.getnum()
						}
					}, 10000)
				}
				
			},
			// 获取统计
			getnum() {
				this.$http.post(api.statistics, )
					.then((res) => {
						if (res.code == 1) {
							this.setnum = res.data
						}
					})
			},
			// 菜单折叠
			isCollapse() {
				this.$EventBus.emit("IsCollapse");
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.read, {
						id: this.$store.state.userinfo.id
					})
					.then((res) => {
						if (res.code == 1) {
							this.setdata = res.data
							sessionStorage.setItem('realname', res.data.real_name)
						}
					})

			},
			// 退出登录
			signout() {
				ElMessageBox.confirm(
						'是否确定退出登录?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						let getroute = []
						this.$store.dispatch('GETNAVLIST', getroute)
						sessionStorage.removeItem('token')
						sessionStorage.removeItem('admininfo')
						this.$router.push('/login')
						ElMessage({
							type: 'success',
							message: '退出成功',
						})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>


<style lang="scss" scoped>
	::v-deep.el-dropdown-menu__item {
		font-size: 13px;
	}

	.head {
		display: flex;
		align-items: center;
		padding: 0 10px;
		width: 100%;
		font-size: 13px;
		height: 50px;
		background-color: #FFFFFF;

		.user {
			display: flex;
			align-items: center;

			.username {
				margin-left: 30px;
				cursor: pointer;


				img {
					width: 32px;
					height: 32px;
					margin: 0 10px;
					border-radius: 50%;
				}

				.icon-xiala {
					font-size: 14px;
					color: #999999;
				}
			}

			.msg {
				cursor: pointer;
				padding-right: 55px;
				position: relative;

				span {
					width: 18px;
					text-align: center;
					line-height: 18px;
					height: 18px;
					font-size: 10px;
					background-color: #f56c6c;
					color: #FFFFFF;
					position: absolute;
					right: 0;
					top: 0;
					border-radius: 50%;
				}

				i {
					font-size: 22px;
				}
			}
		}

		.route-nav {
			flex: 1;
			margin-left: 30px;

			.home {
				font-weight: bold;
				cursor: pointer;
			}

			.home:hover {
				color: #5473E8;
			}

			.line {
				margin: 0 12px;
				font-weight: bold;
				display: inline-block;
			}
		}

		.icon-daohang {
			cursor: pointer;
			font-size: 22px;
			font-weight: bold;
		}
	}
</style>
