<template>
	<view class="menu" :class="[isLogo?'menuw':'']">
		<div class="logo">
			<h1 v-if="isLogo" @click="$router.push('/')">
				<p>直通联办助手管理平台</p>
			</h1>
		</div>
		<el-menu router :collapse="isCollapse" active-text-color="#ffffff" background-color="#2f3447"
			class="el-menu-vertical-demo" :default-active="$route.path" text-color="#e0e2ea">
			<el-sub-menu :index="index+1" v-for="(item,index) in menulist" :key="index">
				<template #title>
					<el-icon>
						<i v-if="item.name=='职位管理'" class="icon iconfont icon-zhiyebingbaogaoshangbao"></i>
						<i v-if="item.name=='账号管理'" class="icon iconfont icon-yonghu1"></i>
						<i v-if="item.name=='角色管理'" class="icon iconfont icon-jiaoseguanli"></i>
						<i v-if="item.name=='审核管理'" class="icon iconfont icon-yishenhe"></i>
						<i v-if="item.name=='通知管理'" class="icon iconfont icon-tongzhi1"></i>
						<i v-if="item.name=='轮播管理'" class="icon iconfont icon-tupian"></i>
						<i v-if="item.name=='乡镇管理'" class="icon iconfont icon-shouye"></i>
						<i v-if="item.name=='反馈管理'" class="icon iconfont icon-fankui"></i>
						<i v-if="item.name=='单位管理'" class="icon iconfont icon-bumenpingyi"></i>
						<i v-if="item.name=='分类管理'" class="icon iconfont icon-fenlei"></i>
						<i v-if="item.name=='工单管理'" class="icon iconfont icon-gongnengleixing1"></i>
						<i v-if="item.name=='系统配置'" class="icon iconfont icon-shezhi2"></i>
					</el-icon>
					<span>{{item.name}}</span>
				</template>
				<el-menu-item-group>
					<el-menu-item v-for="(items,indexs) in item.children" :key="indexs"
						@click="handleselect(items.children,items.name,items.path)" :index="items.path">
						{{items.name}}
					</el-menu-item>
				</el-menu-item-group>
			</el-sub-menu>
		</el-menu>
	</view>
</template>

<script>
	export default {
		data() {
			return {
				isCollapse: false,
				isLogo: true,
				menulist: [],
				btnList: []
			}
		},
		computed: {
			menulist() {
				return this.$store.state.menulist
			}
		},
		mounted() {
			var that = this
			this.$EventBus.on('IsCollapse', function() {
				that.isCollapse = !that.isCollapse
				that.isLogo = !that.isLogo
			})
		},
		methods: {
			handleselect(children, title, url) {
				// 获取路由
				let list = this.$store.state.navlist
				// 防止点击添加重复路由
				let leg = list.some(i => i.name == title)
				// 提交按钮
				this.btnList = []
				this.quanbtn(children)
				if (leg == true) {
					return
				}
				// 新建路由
				let item = {
					name: title,
					path: url,
					children: children
				}
				// 合并路由
				list.concat(list.push(item))
				// 提交路由
				this.$store.dispatch('GETNAVLIST', list)
			},
			// 权限按钮
			quanbtn(children) {
				if (children) {
					children.forEach(item => {
						this.btnList.push(item.typename)
					})
					this.$store.dispatch('BTNLIST', this.btnList)
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.menuw ::v-deep .el-menu {
		width: 255px !important;
	}
	.el-menu-vertical-demo{
		height: calc(100vh - 100px);
		overflow: hidden;
		overflow-y: auto;
	}

	.icon {
		font-size: 18px;
	}

	.logo {
		height: 100px;
		cursor: pointer;
		overflow: hidden;
		text-align: center;
		line-height: 100px;
		color: #ffffff;

		h1 {
			font-size: 20px;
			white-space: nowrap;
		}
	}

	::v-deep .el-menu {
		border: none !important;
	}

	::v-deep .el-sub-menu__title,
	::v-deep .el-menu-item {
		font-size: 13px !important;
		height: 45px;
		line-height: 45px;
		
	}

	::v-deep .el-menu-item.is-active {
		color: #FFFFFF;
		background: #5473E8;
	}
</style>
